<script lang="ts" setup>
import { HoverCard, HoverCardContent, HoverCardTrigger } from "@/components/ui/hover-card";
import { useUserPrivileges } from "@/composables/useUserPrivileges";
import { useAuthStore } from "@/stores/auth";
import { useOrganizationsStore } from "@/stores/organizations";
import { BellIcon, ThumbsUpIcon } from "lucide-vue-next";
import { computed, onMounted } from "vue";
import { Button } from "../ui/button";

const { isOrgManager, isMoreOrEqualToOrgManager, isSystemAdmin } = useUserPrivileges();

const authStore = useAuthStore();
const organizationsStore = useOrganizationsStore();

const currentOrganizationId = computed(() => authStore.selectedOrganization?.id);
const organizationWarnings = computed(() => organizationsStore.organizationWarnings);

const publicWarnings = computed(() => {
    return organizationWarnings.value?.filter(warning => warning.isPublic);
});

const adminWarnings = computed(() => {
    return organizationWarnings.value;
});

const warningsToDisplay = computed(() => {
    if (!currentOrganizationId.value && !isMoreOrEqualToOrgManager.value) return [];

    if (isOrgManager.value) {
        return publicWarnings.value;
    } else if (isSystemAdmin.value) {
        return adminWarnings.value;
    } else {
        return [];
    }
});

onMounted(() => {
    if (!currentOrganizationId.value || !isMoreOrEqualToOrgManager.value) return;

    organizationsStore.fetchOrganizationWarnings(currentOrganizationId.value);
});
</script>

<template>
    <HoverCard :open-delay="200">
        <HoverCardTrigger as-child>
            <Button variant="ghost" class="flex w-full items-center gap-2">
                <BellIcon :size="16" />
                Notifications {{ organizationWarnings }}
            </Button>
        </HoverCardTrigger>
        <HoverCardContent :side="'right'">
            <div class="flex flex-col gap-2 divide-y">
                <p class="body-lg font-medium">Notifications</p>
                <div
                    v-if="warningsToDisplay && warningsToDisplay.length > 0"
                    v-for="(warning, i) in warningsToDisplay"
                    :key="i"
                    class="flex items-start gap-2 pt-2">
                    <p class="body-sm text-muted-foreground">
                        {{ warning.message }}
                    </p>
                </div>
                <div v-else class="flex items-start gap-2 pt-2">
                    <ThumbsUpIcon :size="16" class="text-muted-foreground" />
                    <p class="body-sm text-muted-foreground">Aucune notification</p>
                </div>
            </div>
        </HoverCardContent>
    </HoverCard>
</template>
