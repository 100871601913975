<script setup lang="ts">
import { cn } from "@/lib/utils";
import { ChevronDown, ChevronUp } from "lucide-vue-next";
import { computed } from "vue";
import { useRouter } from "vue-router";

const props = defineProps<{
    link: {
        title: string;
        icon?: any;
        href?: string;
        isDropdown?: boolean;
        subLinks?: Array<{ title: string; href: string }>;
    };
    isServicesDropdownOpen: boolean;
    isMembersDropdownOpen: boolean;
    toggleServicesDropdown: () => void;
    toggleMembersDropdown: () => void;
    isLinkActive: (href: string) => boolean;
}>();
const emit = defineEmits(["close"]);

const router = useRouter();

const isDropdownOpen = computed(() =>
    props.link.title === "Services" ? props.isServicesDropdownOpen : props.isMembersDropdownOpen,
);

const handleLinkClick = (href: string) => {
    router.push(href);
    emit("close");
};

const handleDropdownToggle = () => {
    if (props.link.title === "Services") {
        props.toggleServicesDropdown();
    } else {
        props.toggleMembersDropdown();
    }
};

const isOneOfMySublinksActive = computed(() => {
    if (!props.link.subLinks) return false;

    return props.link.subLinks.some(subLink => props.isLinkActive(subLink.href));
});
</script>

<template>
    <template v-if="link.isDropdown">
        <div @click="handleDropdownToggle" class="cursor-pointer">
            <div class="group flex flex-row flex-nowrap items-center gap-2 text-white">
                <component
                    :is="link.icon"
                    :class="isOneOfMySublinksActive ? 'bg-secondary rounded-full' : 'bg-transparent'"
                    class="group-hover:bg-secondary/80 size-9 overflow-visible rounded-full p-2 transition-colors" />
                <span
                    class="group-hover:decoration-primary underline decoration-transparent decoration-2 underline-offset-4 transition-colors">
                    {{ link.title }}
                </span>
                <component :is="isDropdownOpen ? ChevronUp : ChevronDown" class="size-4" />
            </div>
        </div>
        <ul v-if="isDropdownOpen" class="ml-11 mt-2 space-y-2">
            <li v-for="subLink in link.subLinks" :key="subLink.title">
                <router-link
                    :to="subLink.href"
                    @click.prevent="handleLinkClick(subLink.href)"
                    class="group flex items-center gap-2 text-white">
                    <div
                        :class="props.isLinkActive(subLink.href) ? 'bg-secondary' : 'bg-transparent'"
                        class="group-hover:bg-secondary/60 size-2 rounded-full transition-colors"></div>
                    <span
                        :class="
                            cn(
                                'group-hover:text-secondary/60 transition-colors',
                                props.isLinkActive(subLink.href) ? 'text-secondary' : 'text-white',
                            )
                        ">
                        {{ subLink.title }}
                    </span>
                </router-link>
            </li>
        </ul>
    </template>
    <router-link
        v-else-if="link.href"
        :to="link.href"
        @click.prevent="handleLinkClick(link.href ?? '')"
        class="group flex flex-row flex-nowrap items-center gap-2 text-white">
        <component
            :is="link.icon"
            :class="link.href && props.isLinkActive(link.href) ? 'bg-secondary rounded-full' : 'bg-transparent'"
            class="group-hover:bg-secondary/80 size-9 overflow-visible rounded-full p-2 transition-colors" />
        <span
            class="group-hover:decoration-primary underline decoration-transparent decoration-2 underline-offset-4 transition-colors">
            {{ link.title }}
        </span>
    </router-link>
</template>
