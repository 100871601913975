import { useUserPrivileges } from "@/composables/useUserPrivileges";
import { useAuthStore } from "@/stores/auth";
import type { NavigationGuardNext, RouteLocationNormalized } from "vue-router";

export async function authGuard(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
): Promise<void> {
    const authStore = useAuthStore();
    const { isSystemAdmin, isSystemAdminOrganisation } = useUserPrivileges();

    // Used variables
    const isAuthenticated = await authStore.checkAuthStatus();

    // Handle authentication
    if (to.meta.requiresAuth && !isAuthenticated) {
        return next({ name: "login", query: { redirect: to.fullPath } });
    }

    // Redirect authenticated users from login page
    if (to.name === "login" && isAuthenticated) {
        if (isSystemAdminOrganisation.value) {
            return next({ name: "organizations" });
        }
        return next({ name: "index" });
    }

    // Redirect system admin to organizations page
    if (to.name === "index" && isSystemAdmin.value && isSystemAdminOrganisation.value) {
        return next({ name: "organizations" });
    }

    // Allow navigation
    next();
}
