import * as Sentry from "@sentry/vue";
import { fr } from "date-fns/locale";
import { setDefaultOptions } from "date-fns/setDefaultOptions";
import { createPinia } from "pinia";
import { createApp } from "vue";
import App from "./App.vue";
import "./assets/index.css";
import router from "./router";

const pinia = createPinia();
const app = createApp(App);

Sentry.init({
    app,
    dsn: "https://2f18740df49e1c60ef73302fb164103f@o4507930445938688.ingest.de.sentry.io/4507930449150032",
    integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/(?:[\w-]+\.)*cherry\.orbl\.be\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.use(pinia);
app.use(router);

app.mount("#app");

// Date-fns default
setDefaultOptions({ locale: fr });
