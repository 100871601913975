import { api } from "@/lib/api";
import { fetchWithOrgHeader } from "@/lib/fetchWithOrgHeader";
import type {
    CreateOrganizationDTO,
    EstablishmentDTO,
    OnboardOrganizationDTO,
    OnboardResponseDTO,
    OrganizationDTO,
    OrganizationWarningDTO,
    PricingPlanDataDTO,
    SearchOrganizationsResponseDTO,
    UpdateOrganizationDTO,
} from "@/types/dto";
import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { useAuthStore } from "./auth";

export const useOrganizationsStore = defineStore("organizations", () => {
    // State
    const organizations = ref<SearchOrganizationsResponseDTO[]>([]);
    const currentOrganization = ref<OrganizationDTO | null>(null);
    const organizationEstablishments = ref<EstablishmentDTO[]>([]);
    const establishments = ref<EstablishmentDTO[]>([]);
    const establishment = ref<EstablishmentDTO | null>(null);
    const pricingPlanData = ref<PricingPlanDataDTO | null>(null);
    const organizationWarnings = ref<OrganizationWarningDTO[] | null>(null);

    // Other stores
    const authStore = useAuthStore();

    // Computed
    const accessToken = computed(() => authStore.getAccessToken());

    // Actions
    async function searchOrganizations(query: string) {
        try {
            const response = await fetch(api(`/organizations/directory?q=${encodeURIComponent(query)}`), {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken.value}`,
                },
            });

            if (!response.ok) throw new Error("Failed to search organizations");

            const data: SearchOrganizationsResponseDTO[] = await response.json();
            organizations.value = data;
        } catch (error) {
            console.error("Error searching organizations:", error);
            throw error;
        }
    }

    async function getOrganizationById(orgId: string) {
        try {
            const response = await fetchWithOrgHeader(`/organizations/${orgId}`, {
                method: "GET",
                headers: { "Content-Type": "application/json" },
            });

            const data: OrganizationDTO = await response;
            currentOrganization.value = data;
            return data;
        } catch (error) {
            console.error("Error getting organization:", error);
            throw error;
        }
    }

    async function getOrganizationEstablishments(orgId: string) {
        try {
            const response = await fetch(api(`/organizations/${orgId}/establishments`), {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken.value}`,
                },
            });

            if (!response.ok) throw new Error("Failed to get organization establishments");

            const data: EstablishmentDTO[] = await response.json();
            establishments.value = data;
        } catch (error) {
            console.error("Error getting organization establishments:", error);
            throw error;
        }
    }

    async function createOrganization(createData: CreateOrganizationDTO) {
        try {
            const response = await fetchWithOrgHeader("/organizations", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(createData),
            });

            const data: OrganizationDTO = await response;
            organizations.value.push(data);
            return data;
        } catch (error) {
            console.error("Error creating organization:", error);
            throw error;
        }
    }

    async function fetchAllOrganizations() {
        try {
            const response = await fetchWithOrgHeader("/organizations", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            const data: OrganizationDTO[] = await response;
            organizations.value = data;
        } catch (error) {
            console.error("Error fetching organizations:", error);
            throw error;
        }
    }

    async function updateOrganization(orgId: string, updateData: UpdateOrganizationDTO) {
        try {
            const response = await fetchWithOrgHeader(`/organizations/${orgId}`, {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(updateData),
            });

            const data: OrganizationDTO = await response;
            const index = organizations.value.findIndex(org => org.id === orgId);
            organizations.value[index] = data;
            return data;
        } catch (error) {
            console.error("Error updating organization:", error);
            throw error;
        }
    }

    async function triggetNextPricingPlan(orgId: string) {
        try {
            await fetchWithOrgHeader(`/licences/${orgId}/trigger-next-pricing-plan`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
            });
        } catch (error) {
            console.error("Error triggering next pricing plan:", error);
            throw error;
        }
    }

    async function fetchOrganizationEstablishments(orgId: string) {
        try {
            const data = await fetchWithOrgHeader<EstablishmentDTO[]>(`/establishments`, {
                method: "GET",
                headers: { "Content-Type": "application/json" },
                forceOrganizationId: orgId,
            });

            organizationEstablishments.value = data;
        } catch (error) {
            console.error("Error fetching organization establishments:", error);
            throw error;
        }
    }

    async function getEstablishmentById(orgId: string, estId: string) {
        try {
            const response = await fetch(api(`/organizations/${orgId}/establishments/${estId}`), {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken.value}`,
                },
            });

            if (!response.ok) throw new Error("Failed to get establishment");

            const data: EstablishmentDTO = await response.json();
            establishment.value = data;
            return data;
        } catch (error) {
            console.error("Error getting establishment:", error);
            throw error;
        }
    }

    async function onboardOrganization(dto: OnboardOrganizationDTO): Promise<OnboardResponseDTO> {
        try {
            const response = await fetchWithOrgHeader<OnboardResponseDTO>("/organizations/onboard", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(dto),
            });

            return response;
        } catch (error) {
            console.error("Error onboarding organization:", error);
            throw error;
        }
    }

    async function fetchPricingPlanByKey(key: string): Promise<PricingPlanDataDTO> {
        try {
            const response = await fetchWithOrgHeader<PricingPlanDataDTO>(`/licences/pricing-plans/${key}`);
            pricingPlanData.value = response;
            return response;
        } catch (error) {
            console.error("Error fetching pricing plan by key:", error);
            throw error;
        }
    }

    async function fetchOrganizationWarnings(orgId: string): Promise<OrganizationWarningDTO[]> {
        try {
            const response = await fetchWithOrgHeader<OrganizationWarningDTO[]>(`/organizations/${orgId}/warnings`);
            return response;
        } catch (error) {
            console.error("Error fetching organization warnings:", error);
            throw error;
        }
    }

    // Reset store
    function $reset() {
        organizations.value = [];
        currentOrganization.value = null;
        establishments.value = [];
        establishment.value = null;
    }

    return {
        // State
        organizations,
        organizationEstablishments,
        currentOrganization,
        establishments,
        establishment,
        pricingPlanData,
        organizationWarnings,
        // Actions
        searchOrganizations,
        getOrganizationById,
        getOrganizationEstablishments,
        createOrganization,
        fetchAllOrganizations,
        updateOrganization,
        triggetNextPricingPlan,
        fetchOrganizationEstablishments,
        getEstablishmentById,
        onboardOrganization,
        fetchPricingPlanByKey,
        fetchOrganizationWarnings,
        // Reset
        $reset,
    };
});
