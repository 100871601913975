import { cva, type VariantProps } from "class-variance-authority";

export { default as Button } from "./Button.vue";

export const buttonVariants = cva(
    "inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
    {
        variants: {
            variant: {
                default: "bg-primary text-primary-foreground hover:bg-primary/80",
                destructive: "bg-destructive text-destructive-foreground hover:bg-destructive/90",
                outline: "border border-input bg-background hover:bg-accent hover:text-accent-foreground",
                secondary: "bg-secondary text-primary-foreground hover:bg-secondary/80",
                tertiary: "border border-input bg-gray-200 hover:bg-gray-300 hover:text-accent-foreground",
                ghost: "hover:bg-accent hover:text-accent-foreground",
                ghostSecondary: "hover:bg-secondary hover:text-primary-foreground",
                link: "text-primary underline underline-offset-4 hover:text-primary-variant",
                linkSecondary: "text-secondary underline underline-offset-4 hover:text-secondary/80",
                none: "bg-transparent text-primary hover:bg-transparent hover:text-primary/70 rounded-none",
            },
            size: {
                default: "px-6 py-3",
                sm: "rounded-md px-3",
                lg: " rounded-md px-8",
                icon: "h-10 w-10",
            },
        },
        defaultVariants: {
            variant: "default",
            size: "default",
        },
    },
);

export type ButtonVariants = VariantProps<typeof buttonVariants>;
