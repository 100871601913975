<script lang="ts" setup>
import NavigationDesktop from "@/components/NavigationDesktop.vue";
import { cn } from "@/lib/utils";
import { useRoute } from "vue-router";

const route = useRoute();
</script>

<template>
    <div
        :class="
            cn('relative flex h-screen w-full flex-col md:max-h-screen lg:flex-row', {
                'h-full': route.name === 'communication',
            })
        ">
        <NavigationDesktop />
        <main
            id="main"
            :class="
                cn('relative grow max-lg:h-full lg:overflow-y-auto', {
                    'px-10 py-12': route.name !== 'communication',
                })
            ">
            <slot />
        </main>
    </div>
</template>
