import type { PublicOrganizationDTO } from "../types/dto";

const API_URL = import.meta.env.VITE_API_URL;
if (!API_URL) {
    throw new Error("VITE_API_URL is not defined");
}

const APP_URL = import.meta.env.VITE_APP_URL;
if (!APP_URL) {
    throw new Error("VITE_APP_URL is not defined");
}

function getBaseAppUrl() {
    return new URL(APP_URL);
}

function getOrganizationBaseUrl(organization: PublicOrganizationDTO) {
    const baseUrl = new URL(APP_URL);
    const subdomain = organization.key;
    baseUrl.hostname = `${subdomain}.${baseUrl.hostname}`;
    return baseUrl;
}

function getCurrentOrganizationKey() {
    const currentHostname = new URL(window.location.href).hostname;
    const baseAppHostname = getBaseAppUrl().hostname;
    if (!currentHostname.endsWith(baseAppHostname)) {
        console.error("Current hostname does not match base app hostname", { currentHostname, baseAppHostname });
        return null;
    }
    const subDomain = currentHostname.replace(baseAppHostname, "").slice(0, -1);
    return subDomain?.length > 0 ? subDomain : null;
}

export { API_URL, APP_URL, getBaseAppUrl, getCurrentOrganizationKey, getOrganizationBaseUrl };
