import { useUserPrivileges } from "@/composables/useUserPrivileges";
import { useAuthStore } from "@/stores/auth";
import type { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import { getCurrentOrganizationKey } from "../lib/config";

const strictOrganizationGuardPages = ["login", "register", "forgot-password", "reset-password"];

export async function organizationGuard(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
): Promise<void> {
    const authStore = useAuthStore();
    const { isSystemAdmin } = useUserPrivileges();

    // Used variables
    const subDomainOrgKey = getCurrentOrganizationKey();
    const hasSelectedOrganization = !!authStore.selectedOrganization;
    const isStrictDestination = strictOrganizationGuardPages.includes(to.name as string);

    // Handle organization selection
    if (subDomainOrgKey) {
        if (hasSelectedOrganization) {
            if (subDomainOrgKey === authStore.selectedOrganization!.key) {
                return next();
            } else {
                if (isSystemAdmin.value) {
                    if (isStrictDestination) {
                        const organization = await authStore.getOrganizationByKey(subDomainOrgKey);
                        if (organization) {
                            authStore.setSelectedOrganization(organization);
                            return next();
                        }
                    }
                    return next();
                }
                authStore.clearSelectedOrganization();
                authStore.redirectToOrganization(authStore.selectedOrganization!);
            }
        } else {
            const organization = await authStore.getOrganizationByKey(subDomainOrgKey);
            if (organization) {
                authStore.setSelectedOrganization(organization);
                return next();
            }
            authStore.clearSelectedOrganization();
            return authStore.redirectToBase();
        }
    } else {
        authStore.clearSelectedOrganization();
        if (to.name === "selectOrganization" || to.name === "onboarding") {
            return next();
        }
        return next({ name: "selectOrganization" });
    }
}
